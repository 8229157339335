@charset "UTF-8";
@use "settings" as s;

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1680;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

$color: #1E5199;


//font

html,body{
	font-family: source-han-sans-japanese, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 62.5%;
}
body{
	overflow-x: hidden;
	background: #fff;
}
.en{
	font-family: new-frank, sans-serif;
	font-weight: 400;
	font-style: normal;
	text-transform: uppercase;
	&--head{
		letter-spacing: -.08em;
		display: flex;
		align-items: flex-end;
		&::after{
			content: '';
			display: block;
			width: 16px;
			height: 1px;
			background: #000;
		}
		&-invers{
			&::after{
				background: #fff;
			}
		}
	}
}



//utility

.uppercase{
	text-transform: uppercase;
}

//table
.table{
	margin-top: 20px;
	&__term,&__desc{
		font-size: 1.4rem;
		padding: 10px 0;
		border-bottom: 1px solid #b5b5b5;
		line-height: 1.6;
		@include s.mq(w587){
			display: block;
		}
	}
	&__row{
		@include s.mq(w587){
			display: block;
		}
	}
	&__row + &__row{
		@include s.mq(w587){
			margin-top: 10px;
		}
	}
	&__term{
		font-weight: 700;
		border-bottom: 1px solid $color;
		white-space: nowrap;
		padding-right: 1em;
		@include s.mq(w587){
			font-size: 1.6rem;
			border-bottom-width: 2px;
		}
	}
}


//component

.btn{
	display: inline-block;
	text-align: center;
	padding: 12px 25px;
	border-radius: 30px;
	background: #fff;
	font-size: 1.4rem;
	font-weight: 500;
	color: $color;
	cursor: pointer;
	transition: all .2s;
	&:hover{
		background: $color;
		color: #fff;
	}
	&--jp{
		font-weight: 700;
	}
	&--play{
		&::before{
			content: url(../img/common/ico-play.svg);
			display: inline-block;
			margin-right: 2px;
		}
		&:hover{
			&::before{
				content: url(../img/common/ico-play-inverse.svg);
			}
		}
	}
	&--nega{
		background: $color;
		color: #fff;
		border: 1px solid $color;
		&:hover{
			background: #fff;
			color: $color;
		}
	}
}
.btn-cv{
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 1.6rem;
	font-weight: 700;
	width: 264px;
	height: 54px;
	border-radius: 4px;
	box-shadow: 0 3px 0 0 #061c3c;
	background: $color;
	transition: all .2s;
	text-align: center;
	&:hover{
		background: #fff;
		color: $color;
		border: 1px solid $color;
	}
}
.pagenav{
	display: flex;
	justify-content: center;
}


//main
// .main{
// 	height: 100%;
// 	position: relative;
// }

//header
.logo{
	&__img{
		width: 56px;
		height: 56px;
		@include s.mq(w767){
			width: 36px;
			height: 36px;
		}
	}
}
.header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 24px 26px 0 26px;
	z-index: 999;
	@include s.mq(w767){
		padding: 10px 10px 0;
	}
	&__logo{
		position: absolute;
		top: 0;
		left: 0;
	}
	&__inner{
		width: 100%;
		height: 56px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__utility{
		position: absolute;
		top: 8px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 1000;
	}
	&__scroll{
		position: fixed;
		
		//background: rgba(0,0,0,.3);
		background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05) 23%, rgba(0, 0, 0, 0.7));
		backdrop-filter: blur(5px);
		padding: 10px 26px 10px 26px;
		animation: header-down 0.5s forwards;
		@include s.mq(w767){
			padding: 10px 10px 0;
		}
	}
	@keyframes header-down{
		from {
			opacity: 0;
		  transform: translateY(-170px);
		}
		to {
			opacity: 1;
		  transform: translateY(0);
		}
	  }
}
.gnav{
	@include s.mq(w767){
		display: none;
	}
	&__list{
		display: flex;
		justify-content: space-between;
	}
	&__item{
		font-size: 1.4rem;
	}
	&__item + &__item{
		margin-left: 42px;
		@include s.mq(w900){
			margin-left: 30px;
		}
	}
	&__link{
		color: #fff;
		transition: opacity .2s;
		&:hover{
			opacity: .7;
		}
	}
}
// .search{
// 	position: absolute;
// 	top: 9px;
// 	right: 100px;
// 	&__txt{
// 		-webkit-appearance:none;
// 		width: 40px;
// 		height: 40px;
// 		// padding: 20px;
// 		border: none;
// 		background: transparent url("../img/common/ico-search.svg") no-repeat right 10px center;
// 		background-size: 18px 18px;
// 		border-radius: 20px;
// 		transition: all 0.5s;
// 		outline: none;
// 		cursor: pointer;
// 		&:focus{
// 			width: 250px;
// 			padding: 20px 40px 20px 20px;
// 			box-shadow: 0 2px rgba(6,0,1,.26);
// 			background: #fff url("../img/common/ico-search-on.svg") no-repeat right 10px center;
			
// 		}
// 	}
// }
// 以下阿部追記

.mypage{
	margin-right: 10px;
	@include s.mq(w767){
		display: none;
	}
	// &.active{
	// 	display: block;
	// 	.mypage__link{

	// 	}
	// }
	&--drawer{
		display: none;
		text-align: center;
		margin-top: 20px;
		@include s.mq(w767){
			display: block;
		}
	}
	&__link{
		padding: 9px 25px;
		vertical-align: middle;
		&:hover{
			.mypage__icon{
				fill: #fff;
			}
		}
	}
	&__icon{
		fill: $color;
		width: 14px;
		height: auto;
		transition: all .2s;
		vertical-align: top;
	}
}
.menu {
    width: 92px;
    height: 13px;
    //position: relative;
    display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
    padding: 0;
    border: 0;
	color: #fff;
	background:  transparent;
	font-family: new-frank, sans-serif;
	font-weight: 400;
	font-size: 1.4rem;
	letter-spacing: -0.56px;
	
	&__text{
		text-transform: uppercase;
		// position: absolute;
		// left: -50px;
		//line-height: 40px;
		//padding: 0 6px 0;
		//top: 0;
		// height: 40px;
	}
	&__btn{
		width: 48px;
		height: 13px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	&__border{
		width:48px;
		height:1px;
		// left: 0;
		// right: 0;
		transform: translateY(-50%);
		background-color:#fff;
		// position:absolute;
		transition: all .4s ease;
		// &:nth-child(2){
		// 	top:15px;
		// }
		// &:last-child{
		// 	bottom:15px;
		// }
	}
}
// メニュー表示中
.menu-active{
	overflow: hidden;
	.menu{
		&__border{
			&:first-child {
				transform: translateY(6px) rotate(-16deg);
			}
			&:last-child {
				transform: translateY(-6px) rotate(16deg);
			}
		}
	}
}
.drawer{
	background: #1F1F1F;
	color: #fff;
	display: none;
	height: 100vh;// iOS対策が必要
	left: 0;
	padding: 20px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index:900;
	@include s.mq(w767){
		transform: translateY(-20px);
	}
	&__inner{
		align-items: center;
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
	}
	&__wrapper{
		align-items: center;
		max-width: 703px;
		justify-content: center;
	}
	&__head{
		&--sub{
			color: #717171;
			font-size: 1.6rem;
			margin-top: 29px;
			@include s.mq(w767){
				font-size: 1.4rem;
				margin-top: 14px;
			}
		}
	}
	&__list{
		&--second{
			display: flex;
			justify-content: space-between;
			margin-bottom: 22px;
			@include s.mq(w767){
				margin-bottom: 14px;
				// display: block;
			}
		}
		&--sns{
			display: flex;
			align-items: center;
			margin-top: 23px;
			@include s.mq(w767){
				justify-content: center;
				margin-top: 16px;
			}
		}
		&--sub{
			margin-top: 13px;
			@include s.mq(w767){
				margin-top: 8px;
			}
		}
	}
	&__item{
		// font-size: 3.4rem;
		// font-size: min(5vw, 34px);
		// font-size: clamp(2.3rem,3vw,3.4rem);
		font-size: clampVw(18, 34, 34, 768);
		&:not(:last-child){
			border-bottom: 1px solid rgba(255,255,255,.25);
		}
		&--sub{
			// font-size: 2.3rem;
			font-size: clampVw(16, 23, 23, 768);
		}
		&--sub + &--sub{
			margin-top: 13px;
			@include s.mq(w767){
				margin-bottom: 8px;
			}
		}
		&--sns{
			&:not(:last-child){
				margin-right: 12px;
			}
		}
	}
	&__link{
		color: #fff;
		padding: 10px 0;
		display: block;
		&:hover{
			opacity: .6;
		}
		&--second{
			&:hover{
				opacity: .7;
			}
		}
		&--sub{
			color: #fff;
			&:hover{
				opacity: .6;
			}
		}
		&--sns{
			&:hover{
				opacity: .6;
			}
		}
		&--btn{
			border-radius: 6px;
			margin-top:22px;
			display: block;
			text-align: center;
			padding: 26px 0;
			background:$color;
			font-size: 2rem;
			font-weight: 700;
			color: #fff;
			transition: all .2s;
			@include s.mq(w767){
				font-size: 1.6rem;
				padding: 20px 0;
				margin-top: 16px;
			}
			&:hover{
				background: #fff;
				color: $color;
			}
		}
	}
	&__btn{
		&--contact{
			font-weight: 700;
			display: inline-block;
			margin-top: 23px;
			@include s.mq(w767){
				margin-top: 16px;
				display: block;
			}
		}
	}
	.ico-insta{
		height: auto;
		width: 32px;
		fill: #fff;
	}
	.ico-facebook{
		height: auto;
		width: 34px;
		fill: #fff;
	}
	.ico-line{
		height: auto;
		width: 30px;
		fill: #fff;
	}
	.ico-twitter{
		height: auto;
		width: 28.285px;
		fill: #fff;
	}
}
// .menu-active{
// 	position: relative;
// 	.drawer{
// 		background: #1F1F1F;
// 		display: block;
// 		height: 100vh;// iOS対策が必要
// 		left: 0;
// 		// position: fixed;
// 		top: 0;
// 		width: 100vw;
// 		z-index:900;
// 	}
// }

// 阿部追記ここまで

//line

// .line{
// 	position: fixed;
// 	// top: 93vh;
// 	bottom: 10px;
// 	right: -145px;
// 	width: 338px;
// 	height: 165px;
// 	display: flex;
// 	align-items: center;
// 	font-size: 1.6rem;
// 	font-weight: 700;
// 	color: #000;
// 	background: #fff;
// 	padding: 0 10px 0 7px;
// 	border-radius: 8px 0 0 8px;
// 	transition: right .2s;
// 	z-index: 1;
// 	// @include s.mq(w767){
// 	// 	right: 0;
// 	// }
// 	&:hover{
// 		right: 0;
// 		opacity: 1;
// 	}
// 	&__btn{
// 		margin-right: 8px;
// 		width: 29px;
// 	}
// }

.close{
	width: 25px;
	height: 25px;
	padding: 0;
	border: none;
	background: none;
	position: absolute;
	z-index: 10;
	appearance: none;
	cursor: pointer;
	@include s.mq(w767){
		top: 0;
		right: 0;
	}
}

//電力申込み

.application{
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 10;
	@include s.mq(w767){
		left: auto;
		right: 20px;
		bottom: 128px;
	}
	&__btn{	
		width: 168px;
		height: 92px;
		border-radius: 8px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background: $color;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.6rem;
		font-weight: 700;
		line-height: calc(21/ 16);
		text-align: center;
		z-index: 1;
		transition: all .2s;
		&:hover{
			background: #326bbb;
		}
		@include s.mq(w767){
			width: 134px;
			height: 38px;
			font-size: 1.2rem;
			line-height: 1.4;
		}
	}
	&__icon{
		width: 28px;
		height: 40px;
		margin-bottom: 4px;
		@include s.mq(w767){
			width: 12px;
			height: auto;
		}
	}
	&__contents{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// transform: translateY(-7px);
		@include s.mq(w767){
			// transform: translateY(-3px);
			flex-direction: row;
			gap: 4px;
		}
	}
	.close{
		top: -10px;
		right: -10px;
	}
}

// でんき予報

.forecast{
	background: #fff;
	border-radius: 8px;
	width: 181px;
	height: 92px;
	border: 1px solid #d1d1d1;
	position: fixed;
	bottom: 20px;
    left: 210px;
    z-index: 10;
	@include s.mq(w767){
		width: auto;
		height: auto;
		border: none;
		background: none;
		left: auto;
		right: 20px;
		bottom: 74px;
	}
	&__link{
		&:hover{
			.forecast__head{
				background: #326bbb;
			}
			//opacity: 0.7;
		}
		@include s.mq(w767){
			width: 134px;
			height: 38px;
			display: block;
		}
	}
	&__head{
		background: $color;
		border-radius: 8px 8px 0 0;
		color: #fff;
		font-size: 1.7rem;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		padding: 10px 0;
		transition: all .2s;
		@include s.mq(w767){
			width: 134px;
			height: 38px;
			font-size: 1.2rem;
			border-radius: 8px;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			gap:4px;
			&::before{
				content: url(../img/common/ico-forecast.svg);
				display: block;
			}
		}
	}
	&__text{
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 20px;
		color: #000;
		padding: 6px 10px 8px 10px;
		@include s.mq(w767){
			display: none;
		}
	}
	.close{
		top: -10px;
		right: -10px;
	}
}

//スライダー
.splide{
	&__arrows{
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		@include s.mq(w767){
			justify-content: space-between;
		}
	}
	&__arrow{
		-webkit-appearance:none;
		border: none;
		background: transparent;
		cursor: pointer;
		transition: all .2s;
		width: 90px;
		height: 90px;
		@include s.mq(w767){
			width: 50px;
			height: 50px;
			&--prev{
				// display: none;
			}
		}
		padding: 0;
		&:hover{
			opacity: .7;
		}
	}
}

// question
.question{
	background: #fff;
	padding: 90px 20px 40px;
	
	@include s.mq(w767){
		padding: 40px 20px 10px;
	}
	&__box{
		background: #fff;
		border: solid 1px #d1d1d1;
		max-width:940px;
		padding:38px 20px 30px;
		text-align: center;
		margin: 0 auto;
	}
	&__head{
		font-size: 2rem;
		font-size: clampVw(18, 20, 20, 768);
		line-height: 1.3;
		font-weight: 700;
		margin-bottom: 29px;
	}
}


//cta
.cta{
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 20px 100px;
	@include s.mq(w1440){
		display: block;
	}
	@include s.mq(w767){
		padding: 10px 20px 100px;
	}
	&__inner{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 30px 30px;
		grid-auto-flow: row;
		grid-template-areas:
			"cta__box--phone cta__box--web cta__box--line";		  
		.cta__box--phone { grid-area: cta__box--phone; }
		.cta__box--web { grid-area: cta__box--web; }
		.cta__box--line { grid-area: cta__box--line; }
		@include s.mq(w1440){
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 30px 30px;
			grid-auto-flow: row;
			grid-template-areas:
				"cta__box--phone cta__box--phone"
				"cta__box--web cta__box--line";
			.cta__box--phone { grid-area: cta__box--phone; }
			.cta__box--web { grid-area: cta__box--web; }
			.cta__box--line { grid-area: cta__box--line; }
		}
		@include s.mq(w767){
			display: block;
		}
	}
	&__box{
		width: 450px;
		height: 242px;
		border: solid 1px #d1d1d1;
		border-radius: 8px;
		background: #fff;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 20px;
		&--line{
			padding: 12px 12px 0 12px;
		}
		@include s.mq(w1440){
			width: 100%;
		}
		@include s.mq(w767){
			&:not(:first-child){
				margin-top: 20px;
			}
		}
		@include s.mq(w420){
			height: auto;
		}
	}
	&__head{
		font-size: 2rem;
		font-weight: 700;
		margin-top: 32px;
		// &--line{
		// 	color: #fff;
		// 	background: #02c100;
		// 	border-radius: 4px;
		// 	width: 100%;
		// 	height: 59px;
		// 	margin-top: 0;
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// }
	}
	&__tel{
		font-size: 4.4rem;
		letter-spacing: -.08em;
		font-weight: 500;
		line-height: 1;
		margin-top: 16px;
		&-link{
			color: $color;
		}
		&::before{
			content: '';
			display: inline-block;
			background: url(../img/common/ico-phone.svg) no-repeat left center;
			background-size: 100%;
			width: 34px;
			height: 34px;
		}
		@include s.mq(w420){
			font-size: 3.6rem;
			&::before{
				content: '';
				width: 30px;
				height: 30px;
			}
		}
	}
	&__time{
		font-size: 1.6rem;
		font-weight: 700;
		margin: 5px 0 10px 0;
		text-align: center;
	}
	&__text{
		font-size: 1.4rem;
		text-align: center;
		&--line{
			text-align: left;
			font-size: 1.4rem;
			line-height: calc(22 / 14);
			font-weight: 700;
			flex-shrink: 4;
		}
	}
	&__link{
		width: 296px;
		height: 54px;
		font-size: 1.6rem;
		font-weight: 700;
		color: #fff;
		background: $color;
		border: 1px solid $color;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 34px 0 13px 0;
		transition: all .2s;
		&:hover{
			color: $color;
			background: #fff;
			.cta__line{
				fill: $color;
			}
		}
		@include s.mq(w420){
			width: 100%;
		}
	}
	&__line{
		width: 31px;
		margin-right: 7px;
		fill: #fff;
		transition: all .2s;
	}
	.pagenav{
		@include s.mq(w420){
			width: 100%;
		}
	}
}

.line{
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 338px;
	height: 129px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 0 20px;
	z-index: 10;
	@include s.mq(w767){
		width: 134px;
		height: 38px;
		border: none !important;
		padding: 0 !important;
		background: none !important;
		//padding: 0 10px !important;
	}
	.pagenav{
		width: 100%;
	}
	.cta{
		&__head{
			margin: 0;
			@include s.mq(w587){
				font-size: 1.6rem;
			}
		}
		&__link{
			margin: 0;
			@include s.mq(w767){
				width: 134px;
				height: 38px;
				font-size: 1.2rem;
				border-radius: 8px;
			}
		}
		&__line{
			@include s.mq(w767){
				margin-right: 4px;
			}
		}
		&__text{
			font-size: 1.2rem;
			@include s.mq(w767){
				display: none;
			}
		}
	}
	.close{
		top: -10px;
		right: -10px;
	}
}


//footer
.footer{
	width: 100%;
	
	background-color: #fff;
	&__wrap{
		width: 100%;
		max-width: 1410px;
		border-top: 1px solid #d1d1d1;
		padding: 60px 20px 47px;
		margin:0 auto;
		//max-width: 780px;
		// color: #fff;
	}
	&__contents{
		width: 100%;
		max-width: 780px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		@include s.mq(w767){
			display: block;
		}
	}
	&__nav{
		display: flex;
		justify-content: space-between;
		margin-right:20px;
		max-width: 460px;
		width: 70%;
		@include s.mq(w767){
			width: 100%;
			max-width: 100%;
		}
		@include s.mq(w587){
			display: block;
		}
	}
	&__article{
		max-width: 139px;
		width: 30%;
		@include s.mq(w767){
			width: 100%;
			max-width: 30%;
		}
		@include s.mq(w587){
			max-width: 100%;
			&:not(:first-child){
				margin-top: 20px;
			}
		}
	}
	&__head{
		border-bottom: 1px solid #000;
		font-size:1.4rem;
		line-height: 1.8rem;
		padding-bottom: 4px;
	}
	&__list{
		margin-top: 8px;
	}
	&__item{
		font-size:1.2rem;
		margin-top: 8px;
	}
	&__link{
		color: #000;
		&:hover{
			opacity: .6;
		}
	}
	&__logo{
		align-items: center;
		height: auto;
		margin: 54px auto 0;
		width: 188px;
	}
	&__copyright{
		//background: #0c2a54;
    	color: #969696;
		display: block;
		font-size: 1rem;
		padding: 7px 0 30px;
		text-align: center;
	}
}
.contact{
	width:206px;
	@include s.mq(w767){
		margin: 30px auto 0;
	}
	&__btn{
		font-weight: 700;
	}
	&__contact__list{
		display: flex;
	}
	&__link{
		color: #000;
		&:hover{
			opacity: .6;
		}
	}
	&__list{
		align-items: center;
		display: flex;
		justify-content: center;
		margin-top: 13px;
	}
	&__item{
		&:not(:last-child){
			margin-right: 11px;
		}
	}
	.ico-insta{
		height: auto;
		width: 29px;
		fill: $color;
	}
	.ico-facebook{
		height: auto;
		width: 30px;
		fill: $color;
	}
	.ico-line{
		height: auto;
		width: 28px;
		fill: $color;
	}
	.ico-twitter{
		height: auto;
		width: 28.285px;
		fill: $color;
	}
}

