@charset "UTF-8";
@use "reset";
@use "settings" as s;
@use "user_reset";
@use "common";
@use "contents";

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1680;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

$color: #1e5199;

body{
    background: #fff;
}
.kv{
    background: #0b2346;
   height: 300px;
}

.comingsoon{
    width: 100%;
    max-width: 827px;
    padding: 102px 20px 100px 20px;
    margin: auto;
    font-size: 1.6rem;
    line-height:2;
    @include s.mq(w767){
        margin-top: 50px;
    }
    .section__head{
        margin-bottom: 20px;
        font-weight: 700;
    }
    h3,h4,h5,h6{
        font-weight: 700;
        margin-bottom: 5px;
    }
    h3{
        font-size: 1.8rem;
    }
    &__inquiry{
        width: 100%;
        max-width: 500px;
        background: #f4f8ff;
        padding: 10px 20px;
        border: 1px solid #ccc;
        margin: 60px auto 0;
        text-align: center;
        h3{
            font-size: 1.4rem;
            color: $color;
        }
        p{
            font-size: 1.4rem;
            margin-bottom: 0;
        }
        em{
            font-weight: 700;
            font-size: 1.6rem;
        }
    }
}
