@charset "UTF-8";
@use "settings" as s;

$color: #1e5199;
// $linkmark: #29a9e0;
// $key: #00237d;
// $txt: #5c5c5c;

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1680;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

//section

.section{
    width: 100%;
    max-width: 940px;
    margin: auto;
    @include s.mq(w1280){
        max-width: 900px;
    }
	&__head{
		font-size: 2rem;
        line-height: 1;
        text-align: left;
		// letter-spacing: -0.8px;
		&--jp{
			font-weight: 700;
			margin-top: 11px;
            display: block;
            line-height: 1.4;
		}
		&--en{
			//margin: 0 0 10px;
		}
        br{
            display: none;
        }
	}
    &__text{
        font-size: 1.4rem;
        line-height: 2;
        margin-top: 10px;
    }
}

.media{
    margin-top: 72px;
    @include s.mq(w767){
        margin-top: 40px;
    }
    &__wrapper{
        display: flex;
        align-items: center;
        gap: 74px;
        @include s.mq(w980){
			gap: 40px;
		}
        @include s.mq(w767){
			display: block;
		}
        &--rev{
            flex-direction: row-reverse;
        }
    }
    &__wrapper + &__wrapper{
        margin-top: 109px;
        @include s.mq(w767){
			margin-top: 50px;
		}
    }
    &__contents{
        @include s.mq(w767){
			margin-top: 20px;
		}
    }
    &__title{
        font-size: 1.8rem;
        font-weight: 500;
        line-height: calc(28 / 18);
        &--solar{
            &::before{
                content: url(../img/home/solar/icon-solar.svg);
                display: block;
                margin-bottom: 5px;
            }
        }
        &--home{
            &::before{
                content: url(../img/home/solar/icon-home.svg);
                display: block;
                margin-bottom: 5px;
            }
        }
    }
    &__text{
        width: 358px;
        @include s.mq(w980){
			width: 30%;
		}
        @include s.mq(w767){
			width: 100%;
            margin-top: 20px;
		}
    }
    &__img{
        width: 508px;
        @include s.mq(w980){
			width: 60%;
		}
        @include s.mq(w767){
			width: 100%;
		}
    }
    &__copy{
        font-size: 1.4rem;
        line-height: 2;
        margin-top: 12px;
        @include s.mq(w767){
			margin-top: 4px;
		}
    }
}

//キービジュアル
.kv{
	align-items: center;
	background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
	color: #fff;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: relative;
	text-align: center;
    padding: 0 20px;
	&__head{
		display: flex;
		flex-direction: column;
	}
	&__head-sub{
		font-size: 1.4rem;
		font-weight: 500;
		line-height: calc(78 / 64);
		color: #fff;
		text-transform: initial;
		text-align: center;
		&--en{
			font-size: 3.5rem;
            font-size: clampVw(20, 35, 35, 768);
			line-height: 1.11;
			letter-spacing: -2.45px;
			font-weight: 300;
            margin-top: 5px;
		}
	}
	&__head-main{
		font-size: 6.1rem;
        font-size: clampVw(40, 61, 61, 768);
		font-weight: 700;
		line-height: 1;
		margin-top: 8px;
	}
	&__head-sub{
		font-size: 2rem;
		font-weight: 700;
		line-height: 1;
		margin-top: 8px;
	}
	&__copy{
		font-size: 18px;
		font-weight: 700;
		margin-top: 17px;
	}
    &__list{
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: 30px;
        flex-wrap: wrap;
        @include s.mq(w587){
			gap: 10px;
		}
    }
    &__item{
        width: 128px;
        @include s.mq(w587){
			width: 100px;
		}
    }
	.btn{
		margin-top: 35px;
	}
}